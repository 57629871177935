import { Tractor } from '@aircall/tractor';
import { lazy, Suspense } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import styled from 'styled-components';
import i18n from './i18n';

const SuccessPage = lazy(() => import('./success/SuccessPage'));
const SuccessPagev2 = lazy(() => import('./success/SuccessPagev2'));
const ErrorPage = lazy(() => import('./error/ErrorPage'));
const ErrorPageHelpScout = lazy(() => import('./error/ErrorPageHelpscout'));
const SelectEnv = lazy(() => import('./salesforce/SelectEnv'));
const InstallCTI = lazy(() => import('./salesforce/InstallCTI'));
const InstallCTIV4 = lazy(() => import('./salesforcev4/InstallCTI'));
const InstallBullhorn = lazy(() => import('./bullhorn/InstallBullhorn'));
const InstallOmnichannel = lazy(
  () => import('./salesforce/InstallOmnichannel'),
);
const ConfigureCTI = lazy(() => import('./salesforce/ConfigureCTI'));
const ConfigureCTIV4 = lazy(() => import('./salesforcev4/ConfigureCTI'));
const ConfigureOmnichannel = lazy(
  () => import('./salesforce/ConfigureOmnichannel'),
);
const InstallFailed = lazy(() => import('./salesforce/InstallFailed'));
const ConfigureFieldLevelSecurity = lazy(
  () => import('./salesforce/FieldLevelSecurity'),
);
const ConfigureFieldLevelSecurityV4 = lazy(
  () => import('./salesforcev4/FieldLevelSecurity'),
);

const SelectEnvV4 = lazy(() => import('./salesforcev4/SelectEnv'));
const InstallFailedV4 = lazy(() => import('./salesforcev4/InstallFailed'));

const SelectEnvScv = lazy(() => import('./salesforcescv/SelectEnv'));
const InstallFailedScv = lazy(() => import('./salesforcescv/InstallFailed'));
const SetupSCV = lazy(() => import('./salesforcescv/SetupSCV'));
const InstallScvConnectorPackage = lazy(
  () => import('./salesforcescv/InstallScvConnectorPackage'),
);
const ConfigureFieldLevelSecurityScv = lazy(
  () => import('./salesforcescv/FieldLevelSecurity'),
);

const InstallFreshsales = lazy(() => import('./freshsales/InstallFreshsales'));
const InstallMsDynamics = lazy(() => import('./msdynamics/InstallMsdynamics'));
const InstallActiveCampaign = lazy(
  () => import('./activeCampaign/InstallActiveCampaign'),
);
const MissingPermInstallOmnichannel = lazy(
  () => import('./salesforce/MissingPermInstallOmnichannel'),
);
const MigrationSuccessPage = lazy(
  () => import('./success/MigrationSuccessPage'),
);
const InstallHelpscout = lazy(() => import('./helpscout/InstallHelpscout'));
const HelpscoutSetting = lazy(() => import('./helpscout/HelpscoutSetting'));
const InstallZendesk = lazy(() => import('./zendesk/InstallZendesk'));
const InstallZapier = lazy(() => import('./zapier/InstallZapier'));
const Main = styled.div`
  height: 100vh;
  width: 100vw;
`;
const InstallFront = lazy(() => import('./front/InstallFront'));
const InstallFreshdesk = lazy(() => import('./freshdesk/InstallFreshdesk'));
const InstallSegment = lazy(() => import('./segment/InstallSegment'));
const InstallSlack = lazy(() => import('./slack/InstallSlack'));
const App: React.FC<unknown> = () => {
  return (
    <Tractor injectStyle>
      <Main>
        <Router>
          <Suspense fallback={<div />}>
            <Switch>
              <Route path="/success/:state">
                <SuccessPage i18n={i18n} />
              </Route>
              <Route path="/salesforce/env/:state">
                <SelectEnv />
              </Route>
              <Route path="/salesforce/install-cti/:state">
                <InstallCTI />
              </Route>
              <Route path="/salesforce/configure-cti/:state">
                <ConfigureCTI />
              </Route>
              <Route path="/salesforce/install-omnichannel/:state">
                <InstallOmnichannel />
              </Route>
              <Route path="/salesforce/configure-omnichannel/:state">
                <ConfigureOmnichannel />
              </Route>
              <Route path="/salesforce/install-failed">
                <InstallFailed />
              </Route>
              <Route path="/salesforce/configure-field-level-security/:state">
                <ConfigureFieldLevelSecurity />
              </Route>
              <Route path="/salesforce/missing-perm-install-omnichannel/:state">
                <MissingPermInstallOmnichannel />
              </Route>
              <Route path="/salesforcev4/env/:state">
                <SelectEnvV4 />
              </Route>
              <Route path="/salesforcev4/install-cti/:state">
                <InstallCTIV4 />
              </Route>
              <Route path="/salesforcev4/configure-cti/:state">
                <ConfigureCTIV4 />
              </Route>
              <Route path="/salesforcev4/configure-field-level-security/:state">
                <ConfigureFieldLevelSecurityV4 />
              </Route>
              <Route path="/salesforcev4/install-failed">
                <InstallFailedV4 />
              </Route>
              <Route path="/salesforcescv/env/:state">
                <SelectEnvScv />
              </Route>
              <Route path="/salesforcescv/install-pacakge/:state">
                <InstallScvConnectorPackage />
              </Route>
              <Route path="/salesforcescv/configure/:state">
                <SetupSCV />
              </Route>
              <Route path="/salesforcescv/configure-field-level-security/:state">
                <ConfigureFieldLevelSecurityScv />
              </Route>
              <Route path="/salesforcescv/install-failed">
                <InstallFailedScv />
              </Route>
              <Route path="/freshsales/install/:state">
                <InstallFreshsales />
              </Route>
              <Route path="/freshdesk/install/:state">
                <InstallFreshdesk />
              </Route>
              <Route path="/msdynamics/install/:state">
                <InstallMsDynamics />
              </Route>
              <Route path="/zendesk/install/:state">
                <InstallZendesk />
              </Route>
              <Route path="/helpscout/install/:state">
                <InstallHelpscout />
              </Route>
              <Route path="/helpscout/setting/:state">
                <HelpscoutSetting />
              </Route>
              <Route path="/activecampaign/install/:state">
                <InstallActiveCampaign />
              </Route>
              <Route path="/bullhorn/install/:state">
                <InstallBullhorn />
              </Route>
              <Route path="/front/install/:state">
                <InstallFront />
              </Route>
              <Route path="/segment/install/:state">
                <InstallSegment />
              </Route>
              <Route path="/slack/install/:state">
                <InstallSlack />
              </Route>
              <Route path="/zapier/install/:state">
                <InstallZapier />
              </Route>
              <Route path="/successv2/:state">
                <SuccessPagev2 i18n={i18n} />
              </Route>
              <Route path="/migrationSuccess/:state">
                <MigrationSuccessPage i18n={i18n} />
              </Route>
              <Route exact path="/">
                <Redirect to="/success/state" />
              </Route>
              <Route path="/error/helpscout/:state">
                <ErrorPageHelpScout i18n={i18n} />
              </Route>
              <Route path="/error/:state">
                <ErrorPage i18n={i18n} />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </Main>
    </Tractor>
  );
};

export default App;
